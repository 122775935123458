import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "./Pages/Login";
import Register from './Pages/Register';
import EmergencyMessageList from "./Pages/EmergencyMessageList";

export default function RouterRoutes() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/">
          <Route index element={<Login />} />
          <Route path="Register" element={<Register />} />
          <Route path="EmergencyMessageList" element={<EmergencyMessageList />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}