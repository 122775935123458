import { Box, Input, InputLabel, Button } from '@mui/material';
import React from 'react';
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import Config from '../Config.json';
import Axios from 'axios';
import Pusher from 'pusher-js';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Badge from '@mui/material/Badge';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { useNavigate } from 'react-router-dom';
import logo from '../logo.png';

function Login() {
    const navigate = useNavigate();
    const [usernameValid, usernameValidSet] = React.useState(false);
    const [usernameValidMsg, usernameValidMsgSet] = React.useState('Please Enter Email Address');
    const [userPasswordValid, userPasswordValidSet] = React.useState(false);
    const [userinvalidValid, userinvalidValidSet] = React.useState(false);

    const validateLogin = () => {
        var user_email = document.getElementById('user_email').value;
        var user_password = document.getElementById('user_password').value;
        userPasswordValidSet(false);
        usernameValidSet(false);
        userinvalidValidSet(false);
        if (user_email === "") {
            usernameValidMsgSet('Please Enter Email Address');
            usernameValidSet(true);
            return false;
        } else {
            var regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i
            var check_mail = regex.test(user_email);
            if (!check_mail) {
                usernameValidMsgSet('Invalid Email Address');
                usernameValidSet(true);
                return false;
            }
        }
        if (user_password === "") {
            userPasswordValidSet(true);
            return false;
        }
        var formdata = new FormData();
        formdata.append('user_email', user_email);
        formdata.append('user_password', user_password);
        Axios.post(Config.server_url + "/Api/loginuser", formdata).then(response => {
            if (response.data.status) {
                sessionStorage.setItem('session_username', response.data.user_name);
                sessionStorage.setItem('session_userid', response.data.user_id);
                navigate('/EmergencyMessageList');
            } else {
                userinvalidValidSet(true);
            }
        }).catch(response => {
            alert('An error Occured..!');
        });
    }

    const pusher = new Pusher(Config.pusher_app_key, { cluster: Config.pusher_cluster });
    const [messageCount, messageCountSet] = React.useState(0);
    const channel = pusher.subscribe('notification_channel');
    channel.bind_global(function (event, data) {
        if (event === "trigger_messages") {
            messageCountSet(data.new_msg_count);
        }
    });
    React.useEffect(() => {
        Axios.get(Config.server_url + "/Api/getmessagecount").then(response => {
            console.log(response)
            if (response.data.status) {
                messageCountSet(response.data.new_msg_count);
            } else {
                alert(response.data.status);
            }
        }).catch(response => {
            alert('An error Occurred..!');
        });
    }, []);
    return (
        <Box>
            <Box sx={{ flexGrow: 1 }}>
                <AppBar position="static" sx={{ backgroundColor: 'white' }}>
                    <Toolbar>
                        <Box sx={{ flexGrow: 1 }}>
                            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                <img src={logo} width="40px" height="40px" />
                                <Typography variant="string" noWrap color='brown' mt={1} sx={{ fontWeight: 'bold', fontSize: '20px' }}>
                                    SRM Pulse
                                </Typography>
                            </Box>
                        </Box>
                        <Badge badgeContent={messageCount} color="secondary">
                            <NotificationsIcon color="action" />
                        </Badge>
                    </Toolbar>
                </AppBar>
            </Box>
            <Box className="container">
                <form className="form"  >
                    <Box className="content">
                        <h3 className="title">Log In</h3>
                        <Box className="form-group mt-3">
                            <InputLabel>Email address</InputLabel>
                            <Input type="email" id="user_email" className="form-control mt-1" placeholder="Enter email" />
                            <span style={{ color: 'red', display: (!usernameValid) ? 'none' : 'inline-block' }}>{usernameValidMsg}</span>
                        </Box>
                        <Box className="form-group mt-3">
                            <InputLabel>Password</InputLabel>
                            <Input type="password" className="form-control mt-1" id="user_password" placeholder="Enter password" />
                            <span style={{ color: 'red', display: (!userPasswordValid) ? 'none' : 'inline-block' }}>Please Enter Password</span>
                        </Box>
                        <Box className="form-group mt-3">
                            <span style={{ color: 'red', display: (!userinvalidValid) ? 'none' : 'inline-block' }}>Invalid Username and Password</span>
                        </Box>
                        <Box className="d-grid gap-2 mt-3">
                            <Button onClick={validateLogin} variant="contained">SUBMIT</Button>
                        </Box>
                    </Box>
                </form>
            </Box>
        </Box>
    )
}

export default Login