import React from 'react'
import { Box, Input, InputLabel, Button } from '@mui/material';
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import Config from '../Config.json';
import Axios from 'axios';
import { useNavigate } from 'react-router-dom';

function Register() {
    const navigate = useNavigate();
    const [userValid, userValidSet] = React.useState(false);
    const [usernameValid, usernameValidSet] = React.useState(false);
    const [usernameValidMsg, usernameValidMsgSet] = React.useState('Please Enter Email Address');
    const [userPasswordValid, userPasswordValidSet] = React.useState(false);
    const [userPasswordValidMsg, userPasswordValidMsgSet] = React.useState('Please Enter Password');
    const [userValidationCheck, userValidationCheckSet] = React.useState(false);
    const [userValidationMsg, userValidationMsgSet] = React.useState('');
    const [otpBox, otpBoxSet] = React.useState(false);

    const sentOtp = () => {
        var user_name = document.getElementById('user_name').value;
        var user_email = document.getElementById('user_email').value;
        var user_password = document.getElementById('user_password').value;
        userValidSet(false);
        userPasswordValidSet(false);
        usernameValidSet(false);
        userPasswordValidMsgSet('Please Enter Password');
        if (user_name === "") {
            userValidSet(true);
            return false;
        }
        if (user_email === "") {
            usernameValidMsgSet('Please Enter Email Address');
            usernameValidSet(true);
            return false;
        } else {
            var regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i
            var check_mail = regex.test(user_email);
            if (!check_mail) {
                usernameValidMsgSet('Invalid Email Address');
                usernameValidSet(true);
                return false;
            }
        }
        if (user_password === "") {
            userPasswordValidSet(true);
            return false;
        } else if (user_password.length < 8) {
            userPasswordValidMsgSet('Please Enter Minimum 8 Characters');
            userPasswordValidSet(true);
            return false;
        }
        var formdata = new FormData();
        formdata.append('user_name', user_name);
        formdata.append('user_email', user_email);
        formdata.append('user_password', user_password);
        Axios.post(Config.server_url + "/Api/registeruser", formdata).then(response => {
            if (response.data.status) {
                userValidationCheckSet(false);
                otpBoxSet(true);
            } else {
                userValidationCheckSet(true);
                userValidationMsgSet(response.data.message);
            }
        }).catch(response => {
            userValidationCheckSet(true);
            userValidationMsgSet('An error Occurred..!');
        });
    }

    const checkOtp = () => {
        var user_email = document.getElementById('user_email').value;
        var otp_validation = document.getElementById('otp_validation').value;
        if (otp_validation === "") {
            userValidationCheckSet(true);
            userValidationMsgSet('Please Enter OTP');
        }
        var formdata = new FormData();
        formdata.append('user_email', user_email);
        formdata.append('otp_validation', otp_validation);
        Axios.post(Config.server_url + "/Api/checkotp", formdata).then(response => {
            if (response.data.status) {
                navigate('/');
            } else {
                userValidationCheckSet(true);
                userValidationMsgSet(response.data.message);
            }
        }).catch(response => {
            userValidationCheckSet(true);
            userValidationMsgSet('An error Occurred..!');
        });
    }

    return (
        <Box className="container">
            <form className="form">
                <Box className="content">
                <h3 className="title">Register</h3>
                    <Box className="form-group mt-3" sx={{ display: (otpBox) ? 'none' : 'block' }}>
                        <InputLabel>Name</InputLabel>
                        <Input
                            type="text" id="user_name"
                            className="form-control mt-1"
                            placeholder="Enter name"
                        />
                        <span style={{ color: 'red', display: (!userValid) ? 'none' : 'inline-block' }}>Please Enter Name</span>
                    </Box>
                    <Box className="form-group mt-3" sx={{ display: (otpBox) ? 'none' : 'block' }}>
                        <InputLabel>Email address</InputLabel>
                        <Input
                            type="text" id="user_email"
                            className="form-control mt-1"
                            placeholder="Enter email"
                        />
                        <span style={{ color: 'red', display: (!usernameValid) ? 'none' : 'inline-block' }}>{usernameValidMsg}</span>
                    </Box>
                    <Box className="form-group mt-3" sx={{ display: (otpBox) ? 'none' : 'block' }}>
                        <InputLabel>Password</InputLabel>
                        <Input
                            type="password"
                            className="form-control mt-1" id="user_password"
                            placeholder="Enter password"
                        />
                        <span style={{ color: 'red', display: (!userPasswordValid) ? 'none' : 'inline-block' }}>{userPasswordValidMsg}</span>
                    </Box>
                    <Box className="d-grid gap-2 mt-3"  >
                        <Button hidden={otpBox} variant="contained" onClick={sentOtp}>SEND OTP</Button>
                    </Box>
                    <Box className="form-group mt-3" sx={{ display: (!otpBox) ? 'none' : 'block' }}>
                        <InputLabel>Enter OTP</InputLabel>
                        <Input
                            type="text" id="otp_validation"
                            className="form-control mt-1"
                            placeholder="Enter OTP"
                        />
                    </Box>
                    <Box className="d-grid gap-2 mt-3"  >
                        <Button hidden={!otpBox} variant="contained" onClick={checkOtp}>Register</Button>
                    </Box>
                    <Box className="form-group mt-3">
                        <span style={{ color: 'red', display: (!userValidationCheck) ? 'none' : 'inline-block' }}>{userValidationMsg}</span>
                    </Box>
                </Box>
            </form>
        </Box>
    )
}

export default Register