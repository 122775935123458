import React from 'react'
import { Box, Button } from '@mui/material';
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import { useNavigate } from 'react-router-dom';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import Config from '../Config.json';
import Axios from 'axios';
import Pusher from 'pusher-js';
import AppBar from '@mui/material/AppBar';
import NotificationsIcon from '@mui/icons-material/Notifications';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Badge from '@mui/material/Badge';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { Link } from '@mui/material';
import logo from '../logo.png';
import MenuIcon from '@mui/icons-material/Menu';
import InfoIcon from '@mui/icons-material/Info';
import MenuItem from '@mui/material/MenuItem';
import { IconButton } from '@mui/material';
import useSound from 'use-sound';
import NotificationAlert from '../notification_alert.mp3';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import Menu from '@mui/material/Menu';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import Modal from '@mui/material/Modal';

const columns = [
  { id: 'sno', label: 'S.no', minWidth: 5 },
  { id: 'em_requester_name', label: 'Req.Name', minWidth: 100 },
  { id: 'em_location', label: 'Location', minWidth: 10 },
  {
    id: 'em_id',
    label: 'Info',
    minWidth: 10,
  },

];

function EmergencyMessageList() {
  const navigate = useNavigate();
  const [allMessagesarray, allMessagesarraySet] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [open, setOpen] = React.useState(false);
  const [dialogrequestername, dialogrequesternameSet] = React.useState("requester name");
  const [dialogrequesterphone, dialogrequesterphoneSet] = React.useState("requester phone");
  const [dialogrequesterlocation, dialogrequesterlocationSet] = React.useState("requester location");
  const [dialogrequesterdatetime, dialogrequesterdatetimeSet] = React.useState("requester datetime");
  const [messageCount, messageCountSet] = React.useState(0);
  const [alertBox, alertBoxSet] = React.useState('blue');
  const [play] = useSound(NotificationAlert);

  const openDialoginfo = (em_id) => {
    var formdata = new FormData();
    formdata.append('emergency_id', em_id);
    Axios.post(Config.server_url + "/Api/getemergencymessagebyid", formdata).then(response => {
      if (response.data.status) {
        dialogrequesternameSet(response.data.data.em_requester_name);
        dialogrequesterphoneSet(response.data.data.em_phone_no);
        dialogrequesterlocationSet(response.data.data.em_location);
        dialogrequesterdatetimeSet(response.data.data.em_created_at);
        alertBoxSet('white');
        setOpen(true);
      } else {
        alert(response.data.status);
      }
    }).catch(response => {
      alert('An error Occurred..!');
    });
  };

  const clearbadge = (em_id) => {
    Axios.get(Config.server_url + "/Api/clearmessagecount").then(response => {
      if (response.data.status) {
        messageCountSet(0);
      }
    }).catch(response => {
      alert('An error Occurred..!');
    });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const pusher = new Pusher(Config.pusher_app_key, { cluster: Config.pusher_cluster });

  const channel = pusher.subscribe('notification_channel');
  channel.bind_global(function (event, data) {
    if (event === "trigger_messages") {
      alertBoxSet('red');
      messageCountSet(data.new_msg_count);
      dialogrequesternameSet(data.requester_name);
      dialogrequesterphoneSet(data.phone_no);
      dialogrequesterlocationSet(data.location);
      dialogrequesterdatetimeSet(data.em_created_at);
      setOpen(true);
      play(true);
    }
  });
  React.useEffect(() => {
    var session_userid = sessionStorage.getItem('session_userid');
    var session_username = sessionStorage.getItem('session_username');
    if (!session_userid) {
      navigate('/');
    }


    Axios.get(Config.server_url + "/Api/getallemergencymessages").then(response => {
      if (response.data.status) {
        allMessagesarraySet(response.data.data);
      } else {
        alert(response.data.status);
      }
    }).catch(response => {
      alert('An error Occurred..!');
    });
    Axios.get(Config.server_url + "/Api/getmessagecount").then(response => {
      console.log(response)
      if (response.data.status) {
        messageCountSet(response.data.new_msg_count);
      } else {
        alert(response.data.status);
      }
    }).catch(response => {
      alert('An error Occurred..!');
    });
  }, [messageCount]);


  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseProfile = () => {
    setAnchorEl(null);
  };
  const handleLogout = () => {
    sessionStorage.removeItem('session_username');
    sessionStorage.removeItem('session_userid');
    navigate('/');
  };
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: '#FFCCCB',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  return (
    <Box  >
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        fullWidth={true}
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ textAlign: "center", backgroundColor: alertBox, fontWeight: 'bold', fontSize: '20px' }}>
            Requester Information {'\t'}
            <ErrorOutlineIcon id="alert-box" sx={{ color:'black' }}/>
          </Typography>
          <Table>
            <TableBody>
              <TableRow> <TableCell sx={{ fontSize: '15px', fontWeight: 'bold' }}> Requester Name </TableCell> <TableCell sx={{ fontSize: '15px' }}> {dialogrequestername}</TableCell> </TableRow>
              <TableRow> <TableCell sx={{ fontSize: '15px', fontWeight: 'bold' }}> Requester Phone </TableCell> <TableCell> <Link href={'tel:' + dialogrequesterphone} title={dialogrequesterphone}> <LocalPhoneIcon /></Link></TableCell> </TableRow>
              <TableRow> <TableCell sx={{ fontSize: '15px', fontWeight: 'bold' }}> Requester Location </TableCell> <TableCell> <Link href={dialogrequesterlocation} component='a' target='_blank' title={dialogrequesterlocation}><LocationOnOutlinedIcon /></Link></TableCell> </TableRow>
              <TableRow> <TableCell sx={{ fontSize: '15px', fontWeight: 'bold' }}> Requester Date&Time </TableCell> <TableCell sx={{ fontSize: '15px' }}> {dialogrequesterdatetime}</TableCell> </TableRow>
            </TableBody>
          </Table>
          <br/>
          <Box sx={{ display: "flex" }}>
          <Button onClick={handleClose} size='small' sx={{ marginLeft: "auto" }} variant="contained">
            Close
          </Button>
          </Box>
        </Box>
      </Modal>

      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="static" sx={{ backgroundColor: 'white' }}>
          <Toolbar>
            <div>
              <IconButton
                size="large"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
              >
                <MenuIcon/>
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorEl)}
                onClose={handleCloseProfile}
              >
                <MenuItem onClick={handleLogout}>Log Out</MenuItem>
              </Menu>
            </div>
            <Box sx={{ flexGrow: 1 }}>
              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <img src={logo} width="40px" height="40px" />
                <Typography variant="string" noWrap color='brown' mt={1} sx={{ fontWeight: 'bold', fontSize: '24px' }} onClick={handleLogout}>
                  SRM Pulse
                </Typography>
              </Box>
            </Box>
            <Badge badgeContent={messageCount} onClick={clearbadge} color="secondary" sx={{ cursor: 'pointer' }} title="Clear Notifications">
              <NotificationsIcon color="action" />
            </Badge>
          </Toolbar>
        </AppBar>
      </Box>
      <Box sx={{ display: 'flex', mt: 5, justifyContent: 'center' }}>
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
          <TableContainer sx={{ maxHeight: 440 }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead sx={{ backgroundColor: '#cbcbcbde' }}>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell sx={{ fontSize: '15px', fontWeight: 'bold' }}
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {allMessagesarray
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    var index_incr = index;
                    var rowsno = page * rowsPerPage + (index_incr + 1);
                    return (
                      <TableRow tabIndex={-1} key={row.em_id} sx={{ backgroundColor: (row.em_notified_status === '1') ? '' : '#ffc107' }}>
                        {columns.map((column) => {
                          const value = row[column.id];
                          index++;
                          return (
                            <TableCell key={column.id} align={column.align} >
                              {
                                (column.id === "em_id") ? <InfoIcon size="small" color="primary" sx={{ cursor: 'pointer' }} onClick={() => openDialoginfo(value)}></InfoIcon> : (column.id === "sno") ? rowsno : (column.id === "em_location") ? <Link href={value} component='a' target='_blank'><LocationOnOutlinedIcon /></Link> : value
                              }
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={allMessagesarray.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Box>
    </Box >
  )
}

export default EmergencyMessageList




